import React, { useState, useRef, useEffect } from 'react';
import fetch from 'isomorphic-unfetch';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Typography,
  Link,
  TextField,
  Button,
  LinearProgress,
  Divider,
  FormControlLabel, 
  Checkbox
} from '@material-ui/core';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Image, IconText } from 'components/atoms';
import { SectionHeader } from 'components/molecules';
import { Section } from 'components/organisms';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
    position: 'relative',
  },
  section: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: 0,
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 500,
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      justifyContent: 'flex-end',
    },
  },
  cover: {
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(-8),
      marginRight: theme.spacing(-8),
    },
    [theme.breakpoints.up('md')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '47vw',
      maxWidth: 740,
      height: '100%',
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
    },
  },
  image: {
    width: '100%',
    height: 300,
    objectFit: 'cover',
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
      height: '100%',
    },
  },
  content: {
    flex: '0 0 100%',
    maxWidth: '100%',
    [theme.breakpoints.up('md')]: {
      flex: '0 0 50%',
      maxWidth: '50%',
    },
  },
  linearProgress: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  dsgvo: {
    '&& a': {
      color: theme.palette.primary.main,
    },
    '&& h1, h2, h3': {
      marginBottom: 10,
      marginTop: 30,
    },
    '&& p': {
      marginBottom: 20
    },
    '&& ul': {
      marginLeft: 35,
      marginTop: 25,
      marginBottom: 35,
    },
    '&& li': {
      marginBottom: 15,
    }
  }
}));

import { dsgvo } from '../../assets/data';

const Kontakt = () => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });

  //https://chaseohlson.com/hubspot-forms-gatsby
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const [dsgvoState, setDsgvoState] = useState(false);
  const [open, setOpen] = useState(false);

  const formSuccessMessage = 'Vielen Dank für Ihre Nachricht. Wir werden uns in Kürze bei Ihnen melden.';
  const formErrorMessage = 'Leider ist etwas schief gelaufen. Bitte versuchen Sie es noch einmal oder kontaktieren Sie uns direkt per E-mail: info@tenbi.de.';

  const submitForm = e => {
    if (e) e.preventDefault();
		
    const isBrowser = typeof window !== 'undefined';
    const hutk = isBrowser ? null : null; //Cookies.get('hubspotutk')
    const pageUri = isBrowser ? window.location.href : null;
    const pageName = isBrowser ? document.title : null;
    const postUrl = 'https://api.hsforms.com/submissions/v3/integration/submit/6935644/5ed6f7ab-0a7b-42eb-84c6-431750d0c4d0'; // API-Doc: https://developers.hubspot.com/docs/methods/forms/submit_form_v3

    setLoading(true);

    const body = {
      submittedAt: Date.now(),
      fields: [
        {
          name: 'firstname',
          value: firstname,
        },
        {
          name: 'lastname',
          value: lastname,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'phone',
          value: phone,
        },
        {
          name: 'message',
          value: message,
        },
      ],
      context: {
        hutk,
        pageUri,
        pageName,
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: dsgvoState,
          text: "Ich habe die Datenschutzerklärung gelesen und nehme sie hiermit an."
        }
      }
    };

		// These specific headers are required for whatever reason,
		// so don't forget to include them. 
		
    fetch(postUrl, {
      method: 'post',
      body: JSON.stringify(body),
      headers: new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/json, application/xml, text/plain, text/html, *.*',
      }),
    })
      .then(res => res.json())
      .then(() => {
        setSuccess(true);
        setError(false);
        setLoading(false);
        setFirstname('');
        setLastname('');
        setMessage('');
      })
      .catch(err => {
        setSuccess(false);
        setError(err);
        setLoading(false);
      });
  };

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  const handleClickOpen = () => (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApproveClose = () => {
    setDsgvoState(true);
    setOpen(false);
  };

  const dsgvoDialog = ( 
    <Dialog
      open={open}
      onClose={handleClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">Datenschutzerklärung</DialogTitle>
      <DialogContent dividers='paper'>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <div className={classes.dsgvo} dangerouslySetInnerHTML={{ __html: dsgvo.html }} />
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Schließen
        </Button>
        <Button variant="contained" onClick={handleApproveClose} color="primary">
          Akzeptieren
        </Button>
      </DialogActions>
    </Dialog>
  );

  return (
    <div className={classes.root}>
      <Section className={classes.section}>
        <div className={classes.wrapper}>
          <div className={classes.cover}>
            <Image
              src={require('assets/images/contact-workingpeople.jpg')}
              alt="Contact"
              className={classes.image}
              lazyProps={{ width: '100%' }}
            />
          </div>
          <div className={classes.content}>
            <SectionHeader
              title="Kontaktieren Sie uns"
              subtitle=""
              data-aos="fade-up"
              align="center"
            />
            <div>
              <form 
                data-form-id="6935644"
                data-portal-id="5ed6f7ab-0a7b-42eb-84c6-431750d0c4d0"
                disabled={loading}
                onSubmit={submitForm}
                >
                <fieldset disabled={loading} style={{ border : 0 }}>
                  {success && (
                    <Alert severity="success">
                      <AlertTitle>Gesendet</AlertTitle>
                      {formSuccessMessage}
                    </Alert>
                  )}
                  {!success && (
                    <Grid container spacing={isMd ? 4 : 2}>
                      <Grid item xs={12} data-aos="fade-up">
                        <Typography
                          variant="subtitle1"
                          color="textPrimary"
                        >
                          Name
                        </Typography>
                        <TextField
                          placeholder="Ihr vollständiger Name"
                          variant="outlined"
                          size="medium"
                          name="fullname"
                          fullWidth
                          type="text"
                          onChange={e => {
                            var sFullname = e.target.value;
                            setFirstname(sFullname.substr(0, sFullname.indexOf(' ')));
                            setLastname(sFullname.substr(sFullname.indexOf(' ')+1));
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={6} md={6} data-aos="fade-up">
                        <Typography
                          variant="subtitle1"
                          color="textPrimary"
                        >
                          E-Mail geschäftlich
                        </Typography>
                        <TextField
                          placeholder="Ihre E-Mail Adresse"
                          variant="outlined"
                          size="medium"
                          name="email"
                          fullWidth
                          type="email"
                          value={email}
                          onChange={e => setEmail(e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={6} md={6} data-aos="fade-up">
                        <Typography
                          variant="subtitle1"
                          color="textPrimary"
                        >
                          Telefonnummer
                        </Typography>
                        <TextField
                          placeholder="Ihre Telefonnummer"
                          variant="outlined"
                          size="medium"
                          name="number"
                          fullWidth
                          type="phone"
                          value={phone}
                          onChange={e => setPhone(e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} data-aos="fade-up">
                        <Typography
                          variant="subtitle1"
                          color="textPrimary"
                        >
                          Nachricht
                        </Typography>
                        <TextField
                          placeholder={'Ihre Nachricht'}
                          variant="outlined"
                          name="message"
                          fullWidth
                          multiline
                          rows={4}
                          value={message}
                          onChange={e => setMessage(e.target.value)}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} data-aos="fade-up" style={{textAlign:'center'}}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={dsgvoState}
                              onChange={(event) => {
                                setDsgvoState(event.target.checked);
                              }}
                              name="checkedB"
                              color="primary"
                              required
                            />
                          }
                          label={[
                            <Typography className={classes.root}>
                              Ich habe die{' '}
                              <Link href="" onClick={handleClickOpen()}>
                                Datenschutzerklärung 
                              </Link>
                              {' '}gelesen {!isXs ? '' : <br/>} und nehme sie hiermit an.
                            </Typography>,
                            dsgvoDialog
                          ]}
                        />
                      </Grid>
                      {error && (
                        <Alert severity="error">
                          <AlertTitle>Fehler</AlertTitle>
                          {formErrorMessage}
                        </Alert>
                      )}
                      {loading && ( 
                        <div className={classes.linearProgress}>
                          <LinearProgress />
                        </div>
                      )}
                      <Grid item container justify="center" xs={12} >
                        <Button
                          variant="contained"
                          type="submit"
                          color="primary"
                          size="large"
                          disabled={loading}
                        >
                          Senden
                        </Button>
                      </Grid>
                      <Grid item container justify="center" xs={12} style={isMd ? { marginTop:-15 } : {} }>
                        <div>
                          <IconText
                            title="Sichere Verbindung durch SSL-Verschlüsselung" 
                            fontIconClass="fas fa-lock" 
                            color={theme.palette.primary.main} 
                            typographyProps={{variant:"body2"}}/>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </Section>
      <Divider />
    </div>
  );
};

export default Kontakt;
